header {
    height: 65px;
    background: #fff;
    position: fixed;
    top: 0px;
    z-index: 900;
    display: block;
    width: 100%;
    -webkit-box-shadow: 0px 3px 7px 0px #323232;
    -moz-box-shadow: 0px 3px 7px 0px #323232;
    box-shadow: 0px 3px 7px 0px #323232;
    @include tablet {
        background: #383C48;
    }
    @include wide-desktop-only {
        background: #383C48;
    }
    @include desktop-only {
        background: #383C48;
    }
    @include hd-desktop-only {
        background: #383C48;
    }
    @include hdp-desktop-only {
        background: #383C48;
    }
    .logo {
        background: #383C48;
        height: 65px;
        border-bottom: 1px solid #383C48;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -webkit-align-items: center;
        align-items: center;
        padding: 0 25px;
        z-index: 999;
        width: 250px;
        -webkit-transition: width .1s ease;
        transition: width .1s ease;
        position: fixed;
        left: 0;
        top: 0;
        @include lphone {
            width: 150px;
            padding: 0 10px;
        }
        @include wide-desktop-only {
            display: inline-block;
            float: left;
            position: relative;
        }
        @include desktop-only {
            display: inline-block;
            float: left;
            position: relative;
        }
        @include hd-desktop-only {
            display: inline-block;
            float: left;
            position: relative;
        }
        @include hdp-desktop-only {
            display: inline-block;
            float: left;
            position: relative;
        }
        img {
            max-width: 100%;
            max-height: 65px;
            //filter: invert(1);
        }
    }
    .menu-1280 {
        display: none;
        @include wide-desktop-only {
            display: inline-block;
            margin-top: 6.5px;
            background: #383C48;
            color: #fff;

        }
        @include desktop-only {
            display: inline-block;
            margin-top: 6.5px;
            background: #383C48;
            color: #fff;

        }
        @include hd-desktop-only {
            display: inline-block;
            margin-top: 6.5px;
            background: #383C48;
            color: #fff;

        }
        @include hdp-desktop-only {
            display: inline-block;
            margin-top: 6.5px;
            background: #383C48;
            color: #fff;

        }
    }
    .navigation {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-transition: margin .1s ease, width .1s ease;
        transition: margin .1s ease, width .1s ease;
        width: calc(100% - 240px);
        height: 65px;
        background: #ffffff;
        -webkit-align-items: center;
        align-items: center;
        padding: 0;
        position: fixed;
        right: 0;
        left: 240px;
        transition: width .1s ease, left .1s ease;
        @include lphone {
            left: 180px;
            width: calc(100% - 180px);
            background: #383C48;
        }
        @include tablet-only {
            background: #383C48;
        }
        @include wide-desktop-only {
            width: auto;
            display: inline-block;
            position: absolute;
            left: auto;
            background: #383C48;
        }
        @include desktop-only {
            width: auto;
            display: inline-block;
            position: absolute;
            left: auto;
            background: #383C48;
        }
        @include hd-desktop-only {
            width: auto;
            display: inline-block;
            position: absolute;
            left: auto;
            background: #383C48;
        }
        @include hdp-desktop-only {
            width: auto;
            display: inline-block;
            position: absolute;
            left: auto;
            background: #383C48;
        }
        .top-menu {
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
            flex-direction: row;
            margin: auto 0 auto auto;
            padding-left: 0;
            list-style: none;
            @include lphone {
                display: block;
            }
            @include wide-desktop-only {
                margin-top: 21.5px;
            }
            @include desktop-only {
                margin-top: 21.5px;
            }
            @include hd-desktop-only {
                margin-top: 21.5px;
            }
            @include hdp-desktop-only {
                margin-top: 21.5px;
            }
            li {
                padding-right: 15px;
                @include lphone {
                    float: right;
                    &:first-child {
                        padding-bottom: 5px;
                    }
                }
                a {
                    color: #212529;
                    @include tablet {
                        color: rgba(255,255,255,0.4);
                    }
                    @include wide-desktop-only {
                        color: rgba(255,255,255,0.5);
                    }
                    @include desktop-only {
                        color: rgba(255,255,255,0.5);
                    }
                    @include hd-desktop-only {
                        color: rgba(255,255,255,0.5);
                    }
                    @include hdp-desktop-only {
                        color: rgba(255,255,255,0.5);
                    }
                }
            }
        }
    }
} 