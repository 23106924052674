html {
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2); 
        transition: all .3s;
        &:hover{
            background: rgba(0, 0, 0, 0.5);
        }
      }
      &::-webkit-scrollbar-track{
        // box-shadow: inset 0 0 6px rgba(242, 194,0, 0.3);
        background-color: #FAF7F0;
      }
      scrollbar-width: thin!important;
      scrollbar-color:#e8ebf1!important;  
}
.row{
    max-width: 100%;
    @include tablet{
        margin: 0;
    }
}
.hidden {
    display: none;
    border: 0px!important;
    margin: 0;
}
.hidden-ul {
    display: none !important;
}
input,select,textarea{
    &:focus{
        outline: 1px solid #0d6efd;
        border-radius: 0px;
    }
    @include lphone {
        font-size: 11px!important;
    }
    @include desktop-only {
        font-size: 13px!important;
    }
    @include wide-desktop-only {
        font-size: 14px!important;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
button:hover {
    background-color: black !important;
}
.user span {
    padding-right: 7px;
    @include wide-desktop-only {
        padding-right: 5px;
    }
}
.no-margin {
    margin: 0;
}
.quick-links {
    margin-top: 60px;
}
a.btn-print {
    background: #cc6600;
    padding: 12px 17px;
    min-width: 100px;
    text-align: center;
    color: #fff;
    border: none;
    margin-right: 10px;
    margin-bottom: 10px;
    // float: left;
}
body { 
    margin: 0;
    color: #212529;
    text-align: left;
    background-color: #f9fafb;
    height: 100%;
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    font-weight: 400;
    overflow-y: auto;
    @include lphone {
        font-size: 12px;
    }
    @include desktop-only {
        font-size: 13.5px;
    }
    @include wide-desktop-only {
        font-size: 14px;
    }
    &#login {
        background: url(../images/bg-login.jpg) top center no-repeat;
        background-size: cover;
        background-attachment: fixed;
        position: relative;
        .login-form {
            margin: auto;
            width: 100%;
            height: 100vh;
            .box {
                max-width: 600px;
                margin: auto;
                top: calc(50vh - 225px);
                position: relative;
                display: block;
                padding: 0 15px;
                .title {
                    background: url(../admin/img/triangles.png) center center no-repeat rgba(33,150,243,0.8);
                    background-size: cover;
                    border-top: 10px solid #064475;
                    color: #fff;
                    line-height: 60px;
                    font-size: 24px;
                    padding: 25px 0;
                    text-align: center;
                    margin: 0;
                    border: 3px solid rgba(255,255,255,0.15);
                    border-radius: 10px 10px 0 0;
                    border-bottom: 0px;
                    @include phone {
                        font-size: 20px;
                    }
                }
                .login {
                    padding: 35px 50px;
                    background: rgba(0,0,0,0.5);
                    border: 3px solid rgba(255,255,255,0.15);
                    border-radius: 0 0 10px 10px;
                    border-top: 0px;
                    .group {
                        position: relative;
                        margin-bottom: 45px;
                        input {
                            font-size: 18px;
                            padding: 10px;
                            -webkit-appearance: none;
                            display: block;
                            background: none;
                            color: rgba(255,255,255,0.7);
                            width: 100%;
                            border: none;
                            border-radius: 0;
                            border-bottom: 1px solid rgba(255,255,255,0.7);
                            -webkit-box-sizing: border-box;
                            -moz-box-sizing: border-box;
                            box-sizing: border-box;
                            &::placeholder {
                                color: rgba(255,255,255,0.7);
                            }
                            &:focus {
                                outline: none;
                            }
                        }
                    }
                    .log {
                        margin: 0;
                        .admin-login {
                            position: relative;
                            display: inline-block;
                            padding: 12px 24px;
                            margin: .3em 0 1em 0;
                            width: 100%;
                            vertical-align: middle;
                            color: #fff;
                            font-size: 16px;
                            line-height: 20px;
                            -webkit-font-smoothing: antialiased;
                            text-align: center;
                            letter-spacing: 1px;
                            background: transparent;
                            border: 0;
                            border-bottom: 2px solid #3160B6;
                            cursor: pointer;
                            transition: all 0.15s ease;
                            background: rgba(39,110,204,0.7);
                            text-shadow: 1px 1px 0 rgba(39,110,204,0.5);
                        }
                    }
                    .copyright {
                        color: rgba(255,255,255,0.7);
                        text-align: center;
                        margin: auto;
                        display: block;
                        font-size: 16px;
                        margin-top: 10px;
                        a {
                            color: rgba(255,255,255,0.7);
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}
h1, h2, h3, h4, h5, h6 {
    color: #000000;
}
a {
    color: #212529;
    text-decoration: none;
    &:hover {
        color: #212529;
        text-decoration: none;
    }
}
#container {
    margin-top: 61px;
    position: relative;
    display: flex;
    min-height: calc(100vh - 61px); 
    .left-side {
        min-height: 100%!important;
        float: left;
        overflow: auto;
        flex-shrink: 0;
        display: block;
        width: 250px;
        //position: relative;
        -webkit-box-shadow: 0 8px 10px 0 rgba(183, 192, 206, 0.2);
        box-shadow: 0 8px 10px 0 rgba(183, 192, 206, 0.2);
        background: #2D323E;
        min-height: calc(100vh - 61px); 
        @include wide-desktop-only {
            display: none;
        }
        @include desktop-only {
            display: none;
        }
        @include hd-desktop-only {
            display: none;
        }
        @include hdp-desktop-only {
            display: none;
        }
        @include fhd-desktop-only {
            position: absolute;
        }
        @include qhd-desktop-only {
            position: absolute;
        }
        @include uhd-desktop-only {
            position: absolute;
        }
        .copyright {
            color: #cccc;
            margin-left: 10px;
            padding: 7px 0;
            a {
                color: #ccc;
            }
        }
    }
    .right-side {
        width: 100%;
        float: right;
        overflow: auto;
        @include fhd-desktop-only {
            margin-left: 250px;
        }
        @include qhd-desktop-only {
            margin-left: 250px;
        }
        @include uhd-desktop-only {
            margin-left: 250px;
        }
        .page {
            padding: 0 0 25px;
            overflow: auto;
            @include lphone {
                padding: 0 0 20px;
            }
            .title-index {
                line-height: 40px;
                padding: 30px 0 20px 20px;
                color: #212529;
                font-size: 24px;
                width: 100%;
                font-weight: normal;
            }
            .quick-links {
                .box-ind {
                    text-align: center;
                    .box-home {
                        display: inline-block;
                        margin: auto;
                        padding: 70px;
                        margin-bottom: 70px;
                        background-color: #fff;
                        min-width: 325px;
                        @include desktop-only {
                            min-width: 235px;
                            padding: 20px;
                        }
                        @include wide-desktop-only {
                            min-width: 300px;
                            padding: 20px;
                        }
                        h2 {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
            .box-list, .worker_edit {
                width: calc(100% - 20px);
                box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.5);
                -webkit-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.5);
                -moz-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.5);
                -ms-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.5);
                background-color: #fff;
                margin: auto;
                @include lphone {
                    width: 100%;
                }
                @include wide-desktop-only {
                    margin: auto;
                }
                @include hd-desktop-only {
                    margin: auto;
                }
                @include hdp-desktop-only {
                    margin: auto;
                }
                .box-info-image {
                    padding: 40px;
                    text-align: center;
                    img {
                        width: auto;
                        height: 350px;
                    }
                }
                .backend-table {
                    list-style: none;
                    padding-left: 0;
                    margin: 0 0 25px;
                    @include lphone {
                        margin: 0;
                    }
                    @include wide-desktop-only {
                        letter-spacing: -0.7px;
                    }
                    li {
                        border-bottom: 1px solid #e8ebf1;
                        padding: 8px 5px;
                        &.service-status-1 {
                            background: #FFD7D7;
                            cursor: pointer;
                            &:hover {
                                background: #FFC1C1 !important;
                            }
                            &:nth-child(2n) {
                                background: #FFD7D7 !important;
                                &:hover {
                                    background: #FFC1C1 !important;
                                }
                            }
                        }
                        &.service-status-2 {
                            background: #FFC;
                            cursor: pointer;
                            &:hover {
                                background: #FFFF8A !important;
                            }
                            &:nth-child(2n) {
                                background: #FFC !important;
                                &:hover {
                                    background: #FFFF8A !important;
                                }
                            }
                        }
                        &.service-status-3 {
                            background: #EAFADA;
                            cursor: pointer;
                            &:hover {
                                background: #D0F7C4 !important;
                            }
                            &:nth-child(2n) {
                                background: #EAFADA !important;
                                &:hover {
                                    background: #D0F7C4 !important;
                                }
                            }
                        }
                        &:last-child {
                            border-bottom: 0px;
                        }
                        &:first-child {
                            &:hover{
                                background: transparent !important;
                                -webkit-box-shadow: none !important;
                                -moz-box-shadow: none !important;
                                box-shadow: none !important;
                            }
                        }
                        &.list-info {
                            display: flex;
                            flex-wrap: nowrap;
                            line-height: normal;
                            &:nth-child(2n) {
                                background-color: rgba(0,0,0,0.05);
                            }

                            &:hover {
                                -webkit-box-shadow: 0px 2px 4px 0px #3232324d;
                                -moz-box-shadow: 0px 2px 4px 0px #3232324d;
                                box-shadow: 0px 2px 4px 0px #3232324d;
                            }
                            span {
                                word-break: break-word;
                                padding: 0 5px;
                                &:nth-child(2n) {
                                    padding: 0;
                                }
                                @include lphone {
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    &:first-child {
                                        padding-left: 0;
                                        padding-right: 0;
                                        margin-right: 5px;
                                        span {
                                            margin-right: 0px;
                                        }
                                    }
                                    span {
                                        margin-right: 0;
                                        margin-left: 0;
                                    }
                                }
                                &.name {
                                    @include lphone {
                                        text-align: center;
                                    }
                                }
                            }
                            @include lphone {
                                margin-left: 0;
                                margin-right: 0;
                                padding: 15px 8px;
                            }
                        }
                    }
                }
                #backend-tabs {
                    line-height: normal;
                    .edit-page {
                        border-top: 1px solid #e8ebf1;
                        padding: 45px 25px 15px;
                        @include phone {
                            padding:  45px 0 0;
                        }
                        .page-left {
                            padding: 0;
                            text-align: center;
                        }
                        .page-right {
                            padding: 0;
                            text-align: center;
                            line-height: normal;
                            .upit {
                                img {
                                    float: left;
                                    margin: 10px 0;
                                    max-width: 100%;
                                }
                            }
                        }
                    }
                }
                .form-group {
                    margin: 0 15px 15px;
                    &.pr {
                        border-bottom: 1px solid #e8ebf1;
                        padding-bottom: 10px;
                    }
                    .user-com {
                        font-weight: bold;
                    }
                    .note {
                        textarea {
                            // width: 85% !important;
                            // @include tablet {
                            //     width: 100% !important;
                            // }
                            width: 100%;
                            max-width: 800px;
                        }
                        textarea#state {
                            height: 40px;
                        }
                        textarea#note {
                            height: 40px;
                        }
                    }
                    .no-color {
                        padding-left: 10px;
                    }
                    .line {
                        margin-bottom: 15px;
                        background: rgba(0,0,0,0.05);
                        padding: 10px 0 10px 10px;
                        @include lphone {
                            padding: 10px;
                        }
                        &.left {
                            float: left;
                        }
                        &.right {
                            float: left;
                        }
                        label {
                            font-weight: bold;
                        }
                        span.select2-container--default {
                            width: 300px !important;
                            @include phone {
                                width: 100% !important;
                                max-width: 300px;
                                min-width: auto !important;
                                max-width: inherit !important;
                            }
                            @include lphone-only {
                                width: 300px !important;
                                min-width: auto !important;
                                max-width: inherit !important;
                            }
                            @include tablet-only {
                                width: 450px;
                                max-width: inherit;
                            }
                            @include hdp-desktop-only {
                                width: 340px !important;
                            }
                            @include qhd-desktop-only {
                                width: 450px !important;
                            }
                            @include uhd-desktop-only {
                                width: 640px !important;
                            }
                           
                        }
                    }
                    .prices {
                        float: left;
                        margin-bottom: 15px;
                        padding: 10px 0 10px 10px;
                        label {
                            min-width: 100px;
                        }
                        input {
                            width: 200px;
                        }
                    }

                       input, #report {
                            border: 1px solid #e8ebf1;
                            display: inline-block;
                            padding: 10px 15px;
                            font-size: 0.875rem;
                            font-weight: 400;
                            line-height: 1;
                            color: #495057;
                            background-color: #ffffff;
                            background-clip: padding-box;
                            border-radius: 2px;
                            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                            width: 450px;
                            @include lphone {
                                width: 100%;
                            }
                            @include desktop-only {
                                width: 560px;
                            }
                            @include wide-desktop-only {
                                width: 640px;
                                max-width: 98%;
                            }
                            @include wide-desktop-only {
                                width: 640px;
                                max-width: 98%;
                            }
                            @include hd-desktop-only {
                                width: 640px;
                                max-width: 98%;
                            }
                            @include hdp-desktop-only {
                                width: 450px;
                            }
                            @include fhd-desktop-only {
                                width: 450px;
                            }
                            @include qhd-desktop-only {
                                width: 450px;
                            }
                            @include uhd-desktop-only {
                                width: 640px;
                            }
                        }
                        #report {
                            height: 90px;
                            }
                        label {
                            min-width: 150px;
                            margin-bottom: 0;
                        }
                        textarea#comment_new {
                            width: 100%;
                            height: 50px;
                        }
                        input#comment_attachment {
                            float: left;
                            margin-right: 20px;
                        }
                        p{
                            border: 1px solid #e8ebf1;
                            display: inline-flex;
                            padding: 10px 15px;
                            font-size: 0.875rem;
                            font-weight: 400;
                            line-height: 1;
                            color: #495057;
                            background-color: #ffffff;
                            background-clip: padding-box;
                            border-radius: 2px;
                            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                            width: 80%;
                            height: 45px;
                            overflow: hidden;
                            @include lphone {
                                width: 100%;
                            }
                        }
                        textarea {
                            border: 1px solid #e8ebf1;
                            display: inline-block;
                            padding: 10px 15px;
                            font-size: 0.875rem;
                            font-weight: 400;
                            line-height: 1;
                            color: #495057;
                            background-color: #ffffff;
                            background-clip: padding-box;
                            border-radius: 2px;
                            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                            width: 80%;
                            height: 45px;
                            overflow: hidden;
                            @include lphone {
                                width: 100%;
                            }
                            //@include wide-desktop-only {
                            //    width: 640px;
                            //}
                            //@include hd-desktop-only {
                            //    width: 640px;
                            //}
                            @include hdp-desktop-only {
                                width: 450px;
                            }
                            @include fhd-desktop-only {
                                width: 450px;
                            }
                            @include qhd-desktop-only {
                                width: 450px;
                            }
                            //@include uhd-desktop-only {
                            //    width: 640px;
                            //}
                        }
                        select {
                            width: 450px;
                            border: 1px solid #e8ebf1;
                            display: inline-block;
                            padding: 10px 15px;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 1;
                            color: #495057;
                            background-color: #ffffff;
                            background-clip: padding-box;
                            border-radius: 2px;
                            margin-right: 0;
                            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                            &:focus {
                                outline: 1px solid #e8ebf1;
                            }
                            @include lphone {
                                width: 100%;
                            }
                            @include desktop-only {
                                width: 560px;
                            }
                            @include wide-desktop-only {
                                width: 640px;
                                max-width: 98%;
                            }
                            @include wide-desktop-only {
                                width: 640px;
                                max-width: 98%;
                            }
                            @include hd-desktop-only {
                                width: 640px;
                                max-width: 98%;
                            }
                            @include hdp-desktop-only {
                                width: 450px;
                            }
                            @include fhd-desktop-only {
                                width: 450px;
                            }
                            @include qhd-desktop-only {
                                width: 450px;
                            }
                            @include uhd-desktop-only {
                                width: 640px;
                            }
                        }
                    
                }
            }
            .right-header {
                h1 {
                    line-height: 40px;
                    padding: 20px 15px 55px 25px;
                    // background: url(../images/triangles.png) center center rgba(33,150,243,0.95);
                    // color: #212529;
                    // background: #652d90;
                    background: #ceb7e5;                    
                    color: #fff;                    
                    font-size: 20px;
                    background-size: cover;
                    width: 100%;
                    font-weight: normal;
                    i {
                        margin-right: 10px;
                    }
                    @include tablet {
                        font-size: 18px;
                    }
                    @include desktop {
                        padding-bottom: 15px;
                    }
                }
                .back {
                    position: relative;
                    top: -40px;
                    margin-left: 20px;
                    @include lphone {
                        right: 10px;
                        position: absolute;
                        top: 40px;
                    }
                }
                .filter {
                    background: #fff;
                    margin-right: 20px;
                    margin-top: -65px;
                    padding: 10px 20px;
                    right: 0;
                    line-height: 45px;
                    font-size: 13px;
                    color: rgba(0,0,0,0.8);
                    position: absolute;
                    z-index: 10;
                    box-shadow: 0 0 10px 0 rgba(183,192,206,0.2);
                    -webkit-box-shadow: 0 0 10px 0 rgba(183,192,206,0.2);
                    -moz-box-shadow: 0 0 10px 0 rgba(183,192,206,0.2);
                    -ms-box-shadow: 0 0 10px 0 rgba(183,192,206,0.2);
                    .nice-select{
                        height: 40px;
                        margin-right: 8px;
                        width: 300px!important;
                        max-width: 300px;
                        font-size: 13px;
                    }
                    select{
                        border-radius: 0px;
                    }
                    .filter-select-wrapper{
                        width: fit-content;
                        display: inline-block;
                    }
                    .date-input-wrapper{
                        width: 150px;
                        margin-right: 8px;
                    }
                    @include lphone-up{
                        text-align: left;
                    }
                    @include lphone {
                        width: 100%;
                        padding: 10px;
                        position: relative;
                        margin-top: 0;
                        left: 0px;
                        height: 40px;
                        overflow: hidden;
                        // form{
                        //     text-align: right;
                        // }
                        input[type="text"]{
                            width: 100%;
                            margin-bottom: 10px;
                        }
                        .date_field_wrapper{
                            width: 50%;
                            margin-bottom: 10px;
                            input[type="date"]{
                                width: 100%!important;
                                background: transparent;
                            }
                        }
                        .date-input-wrapper{
                            width: 50%;
                            margin-right: 0px;
                        }
                        .date-input-wrapper.padding{
                            padding-left: 5px;
                        }
                        .date_field_wrapper.padding{
                            padding-left: 5px;
                        }
                        .filter-select-wrapper{
                            width: 50%;
                            margin-bottom: 10px;
                            select{
                                width: 100%;
                                max-width: 100%!important;
                                padding-left: 3px;
                                margin: 0px;
                            }
                        }
                        .filter-select-wrapper:nth-child(odd){
                            padding-left: 5px;
                        }
                        .nice-select{
                            margin-bottom: 10px;
                            width: 100%!important;
                            max-width: 100%;
                        }
                    }
                    @include tablet-only {
                        position: relative;
                        margin-right: 0;
                        margin-top: 0;
                    }
                    @include desktop-only {
                        position: relative;
                        margin-right: 0;
                        margin-top: 0;
                    }
                    input {
                        line-height: 38px;
                        border: 1px solid #e8ebf1;
                        padding: 0 5px;
                        max-width: 120px;
                        font-size: 12px;
                        &.unit {
                            width: auto;
                        }
                        &.time{
                            min-width: 50px;
                            width: 70px;
                        }
                        &[type="date"] {
                            // position: absolute;
                            top: 50%;
                            left: 50%;
                            -webkit-transform: translate(-50%, -50%);
                            transform: translate(-50%, -50%);
                            width: 100% !important;
                            // opacity: 0;
                            padding: 0 0 !important;
                            // line-height: initial;
                            height: 40px;
                            // min-width: 50px;
                            // max-width: 100px;
                        }
                        &[type="checkbox"] {
                            min-width: 50px;
                        }
                    }
                    input[type="date"]::-webkit-calendar-picker-indicator {
                        background: transparent;
                        bottom: 0;
                        color: transparent;
                        cursor: pointer;
                        height: auto;
                        left: 0;
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: auto;

                    }
                    textarea {
                        width: 75%;
                        line-height: 25px;
                        min-height: 120px;
                        border: 1px solid #e8ebf1;
                        padding: 10px 20px;
                    }
                    label {
                        &.custom-file-upload {
                            line-height: 38px;
                            border: 1px solid #e8ebf1;
                            padding: 0 20px;
                            margin: 0;
                            display: block;
                            position: relative;
                            &.uploaded {
                                background: #fff;
                                &:after {
                                    content: '\f00c';
                                }
                            }
                            &:after {
                                content: '\f093';
                                font-family: FontAwesome;
                                position: absolute;
                                right: 10px;
                                top: 5px;
                                line-height: 30px;
                            }
                            input[type="file"] {
                                display: none;
                            }
                        }
                        &.date {
                            margin: auto;
                            background: white;
                            padding: 3px 0 3px 40px;
                            white-space: nowrap;
                            background-image: url(../images/check-in.svg);
                            background-repeat: no-repeat;
                            background-position: left center;
                            color: #000103;     
                            border: 1px solid #e8ebf1;
                            background-position-x: 5px;  
                            height: 40px;
                            min-width: 130px;
                            line-height: 31px;
                            @include lphone {
                                width: 170px;
                            }
                        }
                    }
                    .date_field_wrapper,
                    .date-field-wrapper{
                        display: inline-block;
                        input[type="date"]{
                            opacity: 1;
                            position: static;
                            padding: 10px 5px!important;
                            transform: translate(0,0);
                            @include lphone-up{
                                width: 150px!important;
                            }
                        }
                    }                       
                }
            }
            .page-bottom {
                margin: 10px 0;
                line-height: 45px;
                .pagination {
                    display: block;
                    float: left;
                    width: auto;
                    line-height: 45px;
                    padding-left: 10px;
                    @include lphone{
                        float: none;
                    }
                    a {
                        display: inline-block;
                        margin: 0 5px 0 0;
                        min-height: 20px;
                        text-align: center;
                        min-width: 20px;
                        padding: 8px 12px;
                        line-height: 20px;
                        background-color: #fff;
                        border: 1px solid #e8ebf1;
                        &.active {
                            font-weight: 600;
                            background-color: #e8ebf1;
                        }
                    }
                    .pagination-wrapper{
                        display: inline-block;
                        @include lphone{
                            display: block;
                        }
                    }
                }
                form{
                    @include lphone{
                        padding-left: 10px;
                    }
                }
                .pagination_inside {
                    display: block;
                    width: 100%;
                    .total {
                        display: block;
                        float: left;
                        &:after {
                            content: "|";
                            margin: 0 5px;
                        }
                    }
                    .pages {
                        display: block;
                        float: left;
                        .page_numbers {
                            strong {
                                display: inline-block;
                                margin: 12.5px 5px 12.5px 0;
                                height: 20px;
                                text-align: center;
                                width: 20px;
                                line-height: 20px;
                                border: 1px solid #ccc;
                            }
                            a {
                                display: inline-block;
                                margin: 12.5px 5px 12.5px 0;
                                height: 20px;
                                text-align: center;
                                width: 20px;
                                line-height: 20px;
                                border: 1px solid #ccc;
                            }
                        }
                    }
                    .display {
                        display: block;
                        float: left;
                        &:after {
                            content: "|";
                            margin: 0 5px;
                        }
                    }
                }
            }
        }
    }
    .add-new {
        position: fixed;
        z-index: 999;
        bottom: 25px;
        right: 25px;
        background: #009688;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        width: 55px;
        height: 55px;
        display: flex;
        padding: 10px;
        -webkit-box-shadow: 0px 3px 7px 0px #323232;
        -moz-box-shadow: 0px 3px 7px 0px #323232;
        box-shadow: 0px 3px 7px 0px #323232;
        i {
            font-size: 28px;
            vertical-align: sub;
            color: #fff;
            display: block;
            margin: auto;
        }
    }
    input.tabs {
        display: none;
    }
    label.tabs {
        display: inline-block;
        margin: 0 0 -1px;
        padding: 15px 25px;
        font-weight: 500;
        text-align: center;
        color: #212529b5;
        border: 1px solid transparent;
        min-width: auto;
        text-align: center;
        @include lphone {
            padding: 7px 12px;
        }
        &:hover {
            // color: #005493;
            color: #8cc542;
            cursor: pointer; 
        }
        i {
            margin-right: 5px;
        }
    }
    input.tabs:checked {
        + label {
            //color: #005493;
            color: #8cc542;
            border: 1px solid #ccc;
            border-top: 2px solid #005493;
            border-bottom: 1px solid #fff;
        }
        &:first-child + label {
            border-left: none;
        }
    }
    #tab1:checked ~ #content1,
    #tab2:checked ~ #content2,
    #tab3:checked ~ #content3,
    #tab4:checked ~ #content4,
    #tab5:checked ~ #content5 {
        display: block;
        ul {
            li.numere {
                list-style: none;
                float: left;
                width: max-content;
                span {
                    display: block;
                    text-align: center;
                }
                span.inp {
                    
                }
            }
            li.raspis {
                list-style: none;
                float: left;
                width: max-content;
            }
        }
    }
    #backend-tabs {
        section {
            display: none;
        }
    }
}

#content2 .form-group {
    background: rgba(0,0,0,0.05);
    padding: 10px;
}
.comm .form-group {
    p a {
        color: #0d6efd;
    }
    textarea {
        background: #fff !important;
    }
    textarea[readonly] {
        background: rgba(0,0,0,0.05) !important;
    }
}
.comm .user-klijent textarea[readonly] {
    background: rgba(0,0,0,0.2) !important;
}

label[for=public] {
    display: block;
    margin-top: 15px; 
    margin-bottom: 10px !important;
    input {
        width: auto !important;
        margin-right: 10px;
    }
}

label[for=public-att] {
    display: block;
    margin-bottom: 8px !important;
    input {
        width: auto !important;
        margin-right: 10px;
    }
}
.clients .icons .edit-box a:first-child {
    padding-right: 0px;
}

.clients .icons {
    padding: 0;
}

#client_add {
    background-color: rgba(0,0,0,0.2);
    margin-bottom: 40px;
    padding: 20px 0 1px 0;
    input {
        margin: 0 auto 20px;
        display: block !important;
    }
    select {
        margin: 0 auto 20px;
        display: block !important;
    }
}

span.comments {
    position: absolute;
    background: #f44336;
    color: #fff;
    font-weight: 700;
    font-size: 11px;
    border-radius: 50%;
    margin-top: -5px;
    right: 14px;
    width: 15px;
    line-height: 15px;
    text-align: center;
    @include lphone {
        right: 0;
    }
}

input#comment_attachment {
    display: block !important;
}

a.edit_comment {
    // background-color: #005493;
    background-color: #8cc542;

    padding: 10px 15px;
    color: #fff;
    float: right;
    margin-right: 10px;
    @include tablet-only {
        margin-right: 0;
    }
    @include desktop-only {
        margin-right: 3px;
    }
}

a.confirm {
    background: #f44336;
    padding: 10px 15px;
    color: #fff;
    float: right;
}

.system-tools {
    padding: 0 20px 20px;
    display: block;
    margin-top: 20px;
    @include lphone {
        display: block;
        button {
            width: 45%;
            margin-bottom: 10px;
            line-height: 40px;
        }
        a.reset {
            width: 45%;
        }
        a.btn-print {
            width: 45%;
            display: block;
        }
    }
}
button {
    margin-right: 10px;
    flex: 0 auto;
    line-height: 40px;
    min-width: 100px;
    text-align: center;
    color: #fff;
    border: none;
    cursor: pointer;
    &.reset {
        background-color: #f44336;
    }
    &.save {
        background-color: #10b759;
        margin: auto;
        display: inline-block;
    }
    &.apply {
        // background-color: #005493;
        background-color: #8cc542;
    }
    &.submit {
        // background-color: #005493;
        background-color: #8cc542;
    }
    &.search {
        // background-color: #005493;
        background-color: #8cc542;
    }
}
a.reset {
    background-color: #f44336;
    margin-right: 10px;
    flex: 0 auto;
    padding: 12px 17px;
    min-width: 100px;
    text-align: center;
    color: #fff;
    border: none;
    margin-bottom: 10px;
}
.dodaj {
    background-color: #f44336;
    margin-right: 10px;
    flex: 0 auto;
    line-height: 34px;
    min-width: 100px;
    text-align: center;
    color: #fff;
    border: none;
    margin-bottom: 10px;
    @include phone {
        margin-bottom: 0;
        margin-top: 10px;
    }
}
.ponisti {
    display: flex;
    padding: 0 20px 20px;
    a {
        float: left;
        padding: 1px 10px;
        line-height: 40px;
    }
}
a.transfer {
    background-color:#CCCC00;
    margin-right: 10px;
    flex: 0 auto;
    padding: 12px 17px;
    min-width: 100px;
    text-align: center;
    color: #fff;
    border: none;
}
.id-box {
    padding: 0 5px;
    &.card {
        @include phone {
            display: none;
        }
    }
}
.position {
    @include tablet {
        display: none;
    }
}
.info {
    @include tablet {
        display: none;
    }
}
.status {
    text-align: center;
    @include lphone {
        margin-right: -5px;
    }
}
div#client_find_result {
    margin-bottom: 40px;
    padding: 15px 15px 0;
    border: solid 3px #000;
    @include phone {
        padding: 5px 5px 0;
    }
    .line {
        background: rgba(0,0,0,0.2) !important;
    }
}
.attachment-client {
    margin: 0 0 15px;
    input {
        line-height: 38px;
        border: 1px solid #e8ebf1;
        padding: 0 5px;
        min-width: 170px;
    }
}
.icons {
    text-align: end;
    padding: 0 0 0 15px;
    a {
        display: block;
        margin-bottom: 13px; 
        text-align: initial;  
        &:last-child {
            margin-bottom: 0;
        }    
        span {
            margin-left: 3px; 
        }
    }
    .edit-box {
        float: right;
        padding-right: 0px;
        @include lphone {
            padding-right: 0;
        }
        a {
            display: inline-block;
            &:last-child {
                padding-left: 10px;
            }
            &:first-child {
                padding-left: 0;
            }
        }
    }
    .checkbox{
        float: right;
        width: 50%;
    }
    .dropdown {
        &:hover .dropdown-list {
            display: block;
            position: absolute;
            right: 6px;
            border: solid 1px rgba(0, 0, 0, 0.05);
            padding: 15px;
            z-index: 1;
            background-color: #fff;
            text-transform: capitalize;
            min-width: 120px;
        }
        .dropdown-list {
            display: none;
        }
    }
}
a.color-att {
    color: blue;
}
span.att-img {
    display: block;
    margin-top: 5px;
}
#sidebar_toggle {
    display: none;
    width: 21px;
    height: 21px;
    position: fixed;
    font-size: 21px;
    line-height: 21px;
    z-index: 998;
    top: 20px;
    left: 250px;
    color: rgba(255,255,255,0.4);
    @include tablet {
        display: block;
    }
    @include lphone {
        left: 210px;
    }
}
.box-all {
    padding: 0 0 7px 10px;
    a {
        color: #0d6efd;
        font-weight: bold;
    }
}
.filter {
    select {
        border: 1px solid #e8ebf1;
        display: inline-block;
        padding: 5px 8px;
        font-size: 12px;
        font-weight: 400;
        line-height: 1;
        color: #495057;
        background-color: #ffffff;
        background-clip: padding-box;
        border-radius: 2px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        height: 40px;
        max-width: 150px;
        &#user_id {
            @include lphone {
                min-width: 170px;
            }
        }
        @include desktop-only {
            max-width: 180px;
        }
    }
    input {
        &:last-child {
            margin-right: 0;
        }
    }
    button {
        line-height: 39px;
        padding: 0 18px;
        margin-right: 8px;
        min-width: auto;
        &.export_csv {
            background-color: #10b759;
        }
        &:last-child {
            margin-right: 0;
        }
    } 
}

#attendance_add_form {
    width: 450px;
    @include phone {
        width: 100%;
        min-width: 280px;
    }
    select {
        border: 1px solid #e8ebf1;
        display: inline-block;
        padding: 5px 8px;
        font-size: 13px;
        font-weight: 400;
        line-height: 1;
        color: #495057;
        background-color: #ffffff;
        background-clip: padding-box;
        border-radius: 2px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        height: 40px;
        margin: 2% 0;
        &#gate_id {
            display: inline-block;
            width: 49%;
            float: left;
            margin-right: 2%;
        }
        &#direction {
            display: inline-block;
            width: 49%;
        }
        &#worker_id {
            display: block;
            width: 100%;
        }
    }
    button {
        margin: 2% 0;
        @include lphone {
            width: 100px;
        }
    }
}

.date-field{
    position: relative;
    display: inline-block;
}
label.date {
    margin: auto;
    background: white;
    padding: 3px 0 3px 40px;
    white-space: nowrap;
    background-image: url(../images/check-in.svg);
    background-repeat: no-repeat;
    background-position: left center;
    color: #000103;
    border: 1px solid #e8ebf1;
    background-position-x: 5px;
    height: 40px;
    min-width: 130px;
    line-height: 31px;
    font-size: 0.875rem;
    font-weight: 400;
    display: inline-block;
}
input[type="date"] {
    position: absolute;
    top: 50%;
    left: 20%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 40% !important;
    opacity: 0;
    padding: 24px 0 !important;
    line-height: initial;
    height: 40px;
    min-width: 50px;
}
input.date::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
}


dialog#attendance_add_dialog {
    border: 2px solid #e8ebf1;
    box-shadow: 3px 3px 10px 3px rgba(183,192,206,0.2);
    transition: width .1s ease, left .1s ease;
    margin-top: -50px;
}


.webbox {
    &.fullWidth {
        .left-side {
            @include tablet {
                display: block !important;
            }
        }
    }
    .left-side {
        @include tablet {
            display: none !important;
        }
    }
}

.preview_box{
    position: absolute; 
    top: 20%; 
    right: 20%;
    left:20%;
    padding: 30px; 
    display: none;
    background-color: #2D323E;
    color: #fff;
    border: 5px solid rgba(255,255,255,0.4);
    z-index: 999;
    span, a{
        font-weight: 700;
    }
    a {
        color: #ff6;
    }
    .close-preview{
        float: right;
        background-color: #f44336;
        padding:10px;
        color: #fff;
        &:hover{
            color: #fff;
        }
    }
    .full-preview{
        float: right;
        background-color: #026dbe;
        padding:10px;
        color: #fff;
        margin-right: 10px;
        &:hover{
            color: #fff;
        }
    }
    display:none;
}
.preview_box{
    position: absolute; 
    top: 60%;
    transform: translateY(-50%); 
    right: 20%;
    left:20%;
    padding: 30px; 
    display: block;
    background-color: #2D323E;
    color: #fff;
    border: 5px solid rgba(255,255,255,0.4);
    z-index: 999;
    overflow: auto;
    table{
        min-width: 100%;
        width: max-content;
        border-left:1px solid rgba(255,255,255,0.4) ;
        border-right:1px solid rgba(255,255,255,0.4);
        tr{
            border-bottom: 1px solid rgba(255,255,255,0.4);
            td{
                border-right:1px solid rgba(255,255,255,0.4);
                padding: 5px;
            }
            td:last-child{
                border-right: 0px;
            }
        }
        tr:first-child{
            border-top: 1px solid rgba(255,255,255,0.4);
        }
    }
    @include lphone{
    right: 15px;
    left:15px;
    }
    span, a{
        font-weight: 700;
    }
    a {
        color: #ff6;
    }
    .button-wrapper{
        margin-top: 20px;
        text-align: right;
        .close-preview{
            background-color: #f44336;
            padding:10px;
            color: #fff;
            &:hover{
                color: #fff;
            }
        }
        .full-preview{
            background-color: #026dbe;
            padding:10px;
            color: #fff;
            margin-right: 10px;
            &:hover{
                color: #fff;
            }
        }
    }
}



.com {
    float: left;
    margin-top: 10px !important;
}

.sum{
    text-align: right;
    padding-right: 20px!important;
    word-break: keep-all !important;
    overflow: hidden;
}
select{
    background-color: #fff;
    color: #495057;
    border: 1px solid #e8ebf1;
    padding: 10px;
    margin-right: 10px;
    margin-left: 10px;
}

.multiple-checkboxes {
    display: inline-block;
    position: relative;
    line-height: 28px;
    .selekt {
        position: relative;
        cursor: pointer;
        display: inline-block;
        padding: 5px 50px 5px 10px;
        border: 1px solid #e8ebf1;
        &::before {
            position: absolute;
            content: "";
            border-left: 2px solid black;
            border-top: 2px solid black;
            padding: 5px;
            right: 10px;
            top: 20%;
            -moz-transform: rotate(-135deg);
            -ms-transform: rotate(-135deg);
            -o-transform: rotate(-135deg);
            -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
        }  
        &:active::before {
            right: 8px;
            top: 21%;
        }   
    }
    &:focus, &:hover {
        border: none;
        outline: none;
        .box-select {
            display: inline-block;
            position: absolute;
            left: 0;
            top: 39px;
            line-height: 30px;
            border: 1px solid #e8ebf1;
            padding: 5px 10px;
            background-color: #fff;
            max-height: 300px;
            min-width: 160px;
            overflow-y: auto;
            overflow-x: hidden;
            label {
                white-space: nowrap;
                line-height: normal;
                display: block;
                input {
                    margin-right: 7px;
                }
            }
        }
    }
    .box-select {
        display: none;
    } 
}
.available {
    background-color: #EAFADA !important;
}
.sold {
    background-color: #FFC1C1 !important;
}
.change {
    @include tablet {
        display: none;
    }
}

a.edit_client {
    // background-color: #005493;
    background-color: #8cc542;

    padding: 4px 15px;
    color: #fff;
    float: right;
    margin-right: 10px;
    position: relative;
    top: -5px;
}

.container.inner {
    max-width: 1140px;
    margin: auto;
}
.frm_import{
    padding: 10px;
}
.skroly{
    max-height: 60vh;
    overflow-y: scroll;
}
.subtotal{
    text-align: right;
}

.box-plus {
    width: 31%;
}

@include lphone {
    .none {
        display: none;
    }
}

@include tablet-only {
    .id-1200 {
        width: 5.33333%; 
    }
    .user-991 {
        width: 31%;
    }
    .email-1200 {
        width: 22.66667%;
    }
    .tip-1200 {
        width: 10.33333%;
    }
}

@include desktop-only {
    .id-1200 {
        width: 5.33333%;
    }
    .user-1200 {
        width: 20.66667%;
    }
    .tip-1200 {
        width: 10.33333%;
    }
    .email-1200 {
        width: 22.66667%;
    }
}

@include wide-desktop-only {
    .id-1200 {
        width: 5.33333%;
    }
    .user-1200 {
        width: 20.66667%;
    }
    .tip-1200 {
        width: 10.33333%;
    }
    .email-1200 {
        width: 22.66667%;
    }
}
@include hdp-desktop-only {
    .id-1200 {
        width: 5.33333%;
    }
    .user-1200 {
        width: 20.66667%;
    }
    .tip-1200 {
        width: 10.33333%;
    }
    .email-1200 {
        width: 22.66667%;
    }
}
@include hd-desktop-only {
    .id-1200 {
        width: 5.33333%;
    }
    .user-1200 {
        width: 20.66667%;
    }
    .tip-1200 {
        width: 10.33333%;
    }
    .email-1200 {
        width: 22.66667%;
    }
}
@include fhd-desktop-only {
    .id-1200 {
        width: 5.33333%;
    }
    .user-1200 {
        width: 20.66667%;
    }
    .tip-1200 {
        width: 10.33333%;
    }
    .email-1200 {
        width: 22.66667%;
    }
}
@include qhd-desktop-only {
    .id-1200 {
        width: 5.33333%;
    }
    .user-1200 {
        width: 20.66667%;
    }
    .tip-1200 {
        width: 10.33333%;
    }
    .email-1200 {
        width: 22.66667%;
    }
}
.dblclick {
    cursor: pointer;
}

span.select2-container--default {
    border: 1px solid #e8ebf1;
    display: inline-block;
    padding: 5px 8px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    color: #495057;
    background-color: #ffffff;
    background-clip: padding-box;
    border-radius: 2px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: 40px;
    @include tablet {
        max-width: 180px;
    }
}
.select2-container--default .select2-selection--single {
    background-color: #fff;
    border: none !important;
    border-radius: 0 !important;
}

ul.weeks {
    display: none;
    padding-left: 0;
    &.week-curent {
        display: flex;
        list-style: none;
        text-align: center;
        padding: 15px;
        li {
            width: 14.285714285714285714285714285714%;
            &:first-child {
                ul li span.hour {
                    display: block;
                }
            }
            ul {
                list-style: none;
                padding-left: 0;
                li {
                    width: 100%;
                    text-align: left;
                    min-height: 25px;
                    border-bottom: 1px solid #0000002e;
                    border-right: 1px solid #0000002e;
                    span.hour {
                        display: none;
                    }
                }
            }
        }
    }
}



.amount {
    text-align: right;
    padding-right: 10px;
}
.fa-eye, .fa-thumbs-up{
    margin-right: 3px;
}

.different_label{
    label{display: block!important;
    margin-bottom: 10px!important;
    }
}
.diff_label{
    label{
        min-width: 450px !important;
    }
}

.bolder{
    font-weight: 700;
}

input[type=checkbox]{
    width: 100px!important;
    max-width: 100%;
}

.box_holder{
    padding-left: 30px;
    padding-right: 30px;
}

.date_field_wrapper{
    // position: relative;
    input[type="date"]{
        opacity: 1;
        position: static;
        padding: 10px 15px!important;
        width: 450px!important;
        @include lphone{
            width: 100%!important;
        }
        &::-webkit-calendar-picker-indicator{
            background: url(../images/agenda_calendar.png);
            background-size: cover;
            &:hover{
                cursor: pointer;
            }
        }
    }
}

.date-input-wrapper{
    width: 450px;
    @include lphone {
        width: 100%;
    }
    @include desktop-only {
        width: 560px;
    }
    @include wide-desktop-only {
        width: 640px;
        max-width: 98%;
    }
    @include wide-desktop-only {
        width: 640px;
        max-width: 98%;
    }
    @include hd-desktop-only {
        width: 640px;
        max-width: 98%;
    }
    @include hdp-desktop-only {
        width: 450px;
    }
    @include fhd-desktop-only {
        width: 450px;
    }
    @include qhd-desktop-only {
        width: 450px;
    }
    @include uhd-desktop-only {
        width: 640px;
    }
    position: relative;
    display: inline-block;
    img{
        position: absolute;
        right: 15px;
        top: 10px;
        cursor: pointer;
        z-index: 997;
    }
    input[type="date"]{
        right: 0px;
        top: 0px;
        left: auto;
        width: 100%!important;
        max-width: 100%!important;
        z-index: -1;
    }
    input[type="text"]{
        width: 100%!important;
        max-width: 100%!important;
    }
}

div.nice-select{
    max-width: 100%;
    float: none!important;
    display: inline-block;
    width: 450px;
    border-radius: 0px;
    @include lphone {
        font-size: 11px!important;
    }
    @include desktop-only {
        font-size: 13px!important;
    }
    @include wide-desktop-only {
        font-size: 14px!important;
    }
    @include lphone {
        width: 100%;
    }
    @include desktop-only {
        width: 560px;
    }
    @include wide-desktop-only {
        width: 640px;
        max-width: 98%;
    }
    @include wide-desktop-only {
        width: 640px;
        max-width: 98%;
    }
    @include hd-desktop-only {
        width: 640px;
        max-width: 98%;
    }
    @include hdp-desktop-only {
        width: 450px;
    }
    @include fhd-desktop-only {
        width: 450px;
    }
    @include qhd-desktop-only {
        width: 450px;
    }
    @include uhd-desktop-only {
        width: 640px;
    }
    .nice-select-dropdown{
        border-radius: 0px;
        max-width: 100%;
        .nice-select-search-box{
            .nice-select-search{
                width: 100%!important;
            }
        }
    }
    span.current{
        overflow: hidden!important;
        display: flex;
    }
}
.nice-select:active, .nice-select.open, .nice-select:focus{
    border-color: #0d6efd!important;
    border-radius: 2px;
}

.exam_submit{
    float: right!important;
    margin-top: 5px!important;
    display: block!important;
}

.num_circle{
    border-radius: 50%;
    border: 1px solid black;
    width: 14px;
    height: 14px;
}

table.total-list{
    // background-color: #e8ebf1;
    margin-left: 10px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    tbody{
        tr{
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            td{
                border-right: 1px solid rgba(0, 0, 0, 0.15);
                padding: 2px 10px;
            }
            // td:last-child{
            //     border-right: 0px;
            //     font-weight: 600;
            //     text-align: right;
            // }
            td.num {
                text-align: right;
            }
        }
        tr:last-child{
            border-bottom: 0px;
        }
    }
}

.sum-table{
    margin-left: 10px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    max-width: 100%;
    tr{
        td{
            border-left: 1px solid rgba(0, 0, 0, 0.3);
            padding:5px 10px;
        }
    }
    tr:first-child{
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        td{
            font-weight: 600;
        }
    }
}

.filter-title{
    font-size: 18px;
    margin-left: 25px;
}
.filter-list{
    display: flex;
    flex-direction: row;
    list-style: none;
    margin-left: 15px;
    width: 100%;
    // background-color: rgba(0,0,0,0.05);
    padding: 15px 10px;
    li{
        margin-right: 10px;
        a{
            background-color: #005493;
            color: #fff;
            padding: 10px 15px;
            transition: all .2s;
            &:hover{
                background-color: #000;
            }
        }
    }
    li:last-child{
        a{
            background-color: #f44336;
            &:hover{
                background-color: #000;
            }
        }
    }
}

.printelem{
    background-color: #005493;
    color: #fff;
    display: block;
    width: fit-content;
    padding: 5px 10px;
    transition: all .2s;
    &:hover{
        color: #fff;
        background-color: #000;
    }
}

#dump {display: none}
#floatbox {position:fixed;right:1%;top:7%;z-index:998;max-height:60%;max-width:80%}
#floatbox_close {position:absolute;background:url(images/close.png) 0 0 no-repeat;display:block;width:24px;height:24px;right:-5px;top:-5px;z-index:999}
// #floatbox_close:hover {/*background-position:0 -24px*/}
// #floatbox_close:active {/*background-position:0 -48px*/}
#floatbox_frame{background:#fff;padding:20px;border:2px solid #ddd;min-width:400px;min-height:100px;max-height:500px;position:relative;overflow:auto;}
#floatbox_content{text-align:center}