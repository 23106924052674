.nav {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-wrap: wrap;
    flex-direction: column;
    @include wide-desktop-only {
        display: block;
    }
    @include desktop-only {
        display: block;
    }
    @include hd-desktop-only {
        display: block;
    }
    @include hdp-desktop-only {
        display: block;
    }
    h3.title-menu {
        color: rgba(255,255,255,0.5);
        padding: 0 10px;
        line-height: 40px;
        font-size: 14px;
        text-transform: uppercase;
        margin: 5px 0;
        cursor: pointer;
        @include wide-desktop-only {
            color: rgba(255,255,255,0.5);
        }
        @include desktop-only {
            color: rgba(255,255,255,0.5);
        }
        @include hd-desktop-only {
            color: rgba(255,255,255,0.5);
        }
        @include hdp-desktop-only {
            color: rgba(255,255,255,0.5);
        }
    }
    ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        color: rgba(255,255,255,0.5);
        @include wide-desktop-only {
            display: flex;
            flex-direction: row;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
        }
        @include desktop-only {
            display: flex;
            flex-direction: row;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
        }
        @include hd-desktop-only {
            display: flex;
            flex-direction: row;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
        }
        @include hdp-desktop-only {
            display: flex;
            flex-direction: row;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
        }
        li {
            line-height: 35px;
            padding-left: 0px;
            display: block;
            border-top: 1px solid rgba(255,255,255,0.1);
            @include wide-desktop-only {
                padding: 0 15px;
                border: 0;
            }
            @include desktop-only {
                padding: 0 5px;
                border: 0;
            }
            @include hd-desktop-only {
                padding: 0 15px;
                border: 0;
            }
            @include hdp-desktop-only {
                padding: 0 15px;
                border: 0;
            }
            &:hover {
                .side-menu {
                    .li {
                        background: rgba(0,0,0,0.2);
                        a {
                            color: rgba(255,255,255,0.7);
                        }
                    }
                }
                @include wide-desktop-only {
                    .side-menu {
                        display: block;
                        position: absolute;
                        width: 250px;
                        background: #2D323E;
                    }
                }
                @include desktop-only {
                    .side-menu {
                        display: block;
                        position: absolute;
                        width: 250px;
                        background: #2D323E;
                    }
                }
                @include hd-desktop-only {
                    .side-menu {
                        display: block;
                        position: absolute;
                        width: 250px;
                        background: #2D323E;
                    }
                }
                @include hdp-desktop-only {
                    .side-menu {
                        display: block;
                        position: absolute;
                        width: 250px;
                        background: #2D323E;
                    }
                }
            }
            &:last-child {
                border-bottom: 1px solid rgba(255,255,255,0.1);
                @include wide-desktop-only {
                    border-bottom: 0;
                }
                @include desktop-only {
                    border-bottom: 0;
                }
                @include hd-desktop-only {
                    border-bottom: 0;
                }
                @include hdp-desktop-only {
                    border-bottom: 0;
                }
            }
            ul li {
                padding-left: 20px;
                &:hover {
                    background: rgba(0,0,0,0.2);
                    a {
                        color: rgba(255,255,255,0.7);
                    }
                }
                a {
                    color: rgba(255,255,255,0.4);
                }
            }
            @include wide-desktop-only {
               .side-menu {
                   display: none;
                }
            }
            @include desktop-only {
                .side-menu {
                    display: none;
                }
            }
            @include hd-desktop-only {
                .side-menu {
                    display: none;
                }
            }
            @include hdp-desktop-only {
                .side-menu {
                    display: none;
                }
            }
        }
    }
}