a.notifications_show{
    position: fixed;
    right: 0px;
    top: 85px;
    color: rgba(255,255,255,0.4);
    background-color: #2D323E;
    z-index: 998;
    padding: 5px 10px 2px 10px;
    -webkit-box-shadow: -3px 2px 5px 0px rgba(42,42,42,.5);
    -moz-box-shadow: -3px 2px 5px 0px rgba(42,42,42,.5);
    box-shadow: -3px 2px 5px 0px rgba(42,42,42,.5);
    transition: all .3s;
    i{
        font-size: 22px;
    }
    i:nth-child(2){
        display: none;
    }
    span.important_notification{
        background-color: #f44336;
        padding: 1px 4px;
        font-size: 8px;
        color: #fff;
        border-radius: 50%;
        position: absolute;
        top: -2px;
        right: 3px;
    }
    span.important_notification.message_number{
        background-color: #2e7ce4;
        top: auto;
        bottom: -2px;
    }
    &:hover{
        color: rgba(255,255,255,1);
    }
}
div.notifications_box{
    position: fixed;
    overflow: hidden;
    top: 0px;
    right: 0px;
    // width: 250px;
    background-color: #2D323E;
    height: 100vh;
    overflow-y: auto;
    z-index: 999;
    width: 0px;
    padding: 10px 0px;
    transition: all.3s;
    h3{
        color: rgba(255,255,255,0.7);
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 20px;
    }
    h5{
        color:rgba(255,255,255,0.5);
        font-weight: 400;
        font-size: 15px;
        padding:0px 0px 5px 5px ;
        border-bottom: 2px solid rgba(255,255,255,0.1);
        i{
            margin-right: 5px;
        }
    }
    .new_messages_holder{
        margin-bottom: 30px;
        .new_messages_list{
            padding-left: 22px;
        }
        li.new_message{
            color: rgba(255,255,255,0.4);
            border-bottom: 1px solid rgba(255,255,255,0.1);
            padding-bottom: 2px;
            margin-bottom: 5px;
            a.open_chat{
                color: rgba(255,255,255,0.5);
                transition: all .2s;
                &:hover{
                    color: rgba(255,255,255,0.8);
                }
            }
            span{
                color: rgba(255,255,255,0.7);
            }
            &::marker{
                // background-color: rgba(255,255,255,0.4);
                color: #f44336;
            }
        }
        li:last-child{
            border-bottom: 0px;
        }
    }
}
a.notifications_show.showed{
    right: 250px;
    span.important_notification{
        display: none;
    }
    i:first-child{
        display: none;
    }
    i:nth-child(2){
        display: block;
    }
}
a.notifications_show.showed + div.notifications_box{
    width: 250px;
    -webkit-box-shadow: -3px 0px 5px 0px rgba(42,42,42,.5);
    -moz-box-shadow: -3px 0px 5px 0px rgba(42,42,42,.5);
    box-shadow: -3px 0px 5px 0px rgba(42,42,42,.5);
}
.alert_overlay{
    position: fixed;
    background: rgba(0,0,0,0.6);
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0;
    left: 0;
    display: none;
    .task_alert{
        h3{
            text-align: center;
            color: #fff;
            font-size: 22px;
        }
        position: absolute; 
        top: 50%;
        transform: translateY(-50%); 
        right: 20%;
        left:20%;
        padding: 30px; 
        // display: none;
        background-color: #2D323E;
        color: #fff;
        border: 5px solid rgba(255,255,255,0.4);
        z-index: 999;
        @include lphone{
        right: 15px;
        left:15px;
        }
        span, a{
            font-weight: 700;
        }
        a {
            color: #ff6;
        }
        .button-wrapper{
            margin-top: 20px;
            text-align: right;
            .close-preview{
                background-color: #f44336;
                padding:10px;
                color: #fff;
                &:hover{
                    color: #fff;
                }
            }
            .full-preview{
                background-color: #026dbe;
                padding:10px;
                color: #fff;
                margin-right: 10px;
                &:hover{
                    color: #fff;
                }
            }
        }
    }
}
.alert_overlay.active{
    display: block;
}
//PopUpBox
.preview_box_wrapper{
    position: fixed;
    background: rgba(0,0,0,0.6);
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0;
    left: 0;
    display: none;
    overflow: auto;
    .preview_box{
        position: absolute; 
        top: 60%;
        transform: translateY(-50%); 
        right: 20%;
        left:20%;
        padding: 30px; 
        display: block;
        background-color: #2D323E;
        color: #fff;
        border: 5px solid rgba(255,255,255,0.4);
        z-index: 999;
        overflow: auto;
        table{
            min-width: 100%;
            width: max-content;
            border-left:1px solid rgba(255,255,255,0.4) ;
            border-right:1px solid rgba(255,255,255,0.4);
            tr{
                border-bottom: 1px solid rgba(255,255,255,0.4);
                td{
                    border-right:1px solid rgba(255,255,255,0.4);
                    padding: 5px;
                }
                td:last-child{
                    border-right: 0px;
                }
            }
            tr:first-child{
                border-top: 1px solid rgba(255,255,255,0.4);
            }
        }
        @include lphone{
        right: 15px;
        left:15px;
        }
        span, a{
            font-weight: 700;
        }
        a {
            color: #ff6;
        }
        .button-wrapper{
            margin-top: 20px;
            text-align: right;
            .close-preview{
                background-color: #f44336;
                padding:10px;
                color: #fff;
                &:hover{
                    color: #fff;
                }
            }
            .full-preview{
                background-color: #026dbe;
                padding:10px;
                color: #fff;
                margin-right: 10px;
                &:hover{
                    color: #fff;
                }
            }
        }
    }
}